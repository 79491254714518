<template>
  <div class="edit-connection">
    <CForm
      ref="editConnection"
      v-on:submit.prevent="editConnection"
      novalidate
      class="edit-connection-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.editKafka.title"
        submit-title="update"
      />
      <!--  CONTENT  -->
      <div class="form-content content">
        <!--    Connection Type    -->
        <ClFormRow
          :label="texts.editKafka.connectionTypeLabel"
          :toolTipText="descriptions.connectionType"
        >
          <template #content>
            <CInput
              :placeholder="texts.editKafka.connectionTypePlaceholder"
              v-model="model"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--    ID    -->
        <ClFormRow
          :label="texts.editKafka.idLabel"
          :toolTipText="descriptions.id"
        >
          <template #content>
            <CInput
              placeholder
              v-model="formData.id"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--  Created    -->
        <ClFormRow
          :label="texts.editKafka.createdLabel"
          tool-tip-text="Created"
          :toolTipText="descriptions.created"
        >
          <template #content>
            <CInput
              :value="created"
              add-input-classes="col-sm-12"
              required
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--  Space Name  -->
        <ClFormRow
          :label="texts.editKafka.spaceNameLabel"
          :toolTipText="descriptions.spaceName"
        >
          <template #content>
            <CInput
              :value="formData.spaceName"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--   NAME     -->
        <ClFormRow
          :label="texts.editKafka.nameLabel"
          :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
              :placeholder="texts.editKafka.namePlaceholder"
              v-model="formData.name"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
        <!--  Description  -->
        <ClFormRow
          :label="texts.editKafka.descriptionLabel"
          :toolTipText="descriptions.description"
        >
          <template #content>
            <CTextarea
              :placeholder="texts.editKafka.descriptionPlaceholder"
              v-model="formData.description"
              add-input-classes="col-sm-12"
            />
          </template>
        </ClFormRow>
        <!-- Brokers  -->
        <ClFormRow
          :label="texts.editKafka.brokersLabel"
          :toolTipText="descriptions.brokers"
        >
          <template #content>
            <multiselect
              :placeholder="texts.editKafka.brokersPlaceholder"
              v-model="formData.brokers"
              :options="brokerOptions"
              :searchable="false"
              :multiple="false"
              :allow-empty="false"
              :block-keys="['Tab', 'Enter']"
              required
              :is-valid="brokerValidate"
              :invalidFeedback="texts.invalidFeedback"
              @input="onChangeBroker"
              :selectLabel="selectLabel"
              :deselectLabel="selectLabel"
              :class="selectErrorClass"
              class="
                custom-multi-select
                zq--multiselect
                zq--multiselect__select
                zq--multiselect__green
              "
            />
          </template>
        </ClFormRow>
        <!--  Group ID*    -->
        <ClFormRow
          :label="texts.editKafka.groupLabel"
          :toolTipText="descriptions.groupID"
        >
          <template #content>
            <CInput
              :placeholder="texts.editKafka.groupPlaceholder"
              v-model="formData.groupId"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="groupValidate"
            />
          </template>
        </ClFormRow>
        <!--  Topic      -->
        <ClFormRow
          :label="texts.editKafka.topicLabel"
          tool-tip-text="Topic"
          :toolTipText="descriptions.topic"
        >
          <template #content>
            <CInput
              :placeholder="texts.editKafka.topicPlaceholder"
              v-model="formData.topic"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="topicValidate"
            />
          </template>
        </ClFormRow>
        <!--  Transformer ID*  -->

        <CISelectTranformer
          :label="texts.editKafka.transformerLabel"
          tool-tip-text="Transformer ID"
          :toolTipText="descriptions.transformerID"
          v-model="formData.transformerId"
          :value="formData.transformerId"
        />

        <CITagField
          :type="model"
          v-model="formData.tags"
          :label="texts.editKafka.tagsLabel"
          :tooltip="descriptions.tags"
          :disabled="false"
        />

        <CICustomFields
          :type="model"
          :val="formData.customFields"
          @update="formData.customFields = { ...$event }"
        />

        <CIMetaDataField
          v-model="formData.metadata"
          :label="texts.editKafka.metadataLabel"
          :tooltip="descriptions.metadata"
          :disabled="false"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from '@/config';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import CreateHeader from '@/shared/components/CreateHeader';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
import { connections } from '@/config/descriptions/connections';
import { connectionsTexts } from '@/config/pageTexts/connections.json';

export default {
  name: 'EditConnectionKafka',
  components: {
    CreateHeader,
    ClFormRow,
  },
  data() {
    return {
      model: 'KafkaConnection',
      descriptions: {
        ...connections.edit.kafka,
      },
      texts: {
        ...connectionsTexts,
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      removeLabel: formConfig.removeLabel,
      formData: {
        id: '',
        created: '',
        objectType: 'KafkaConnection',
        name: '',
        brokers: '',
        groupId: '',
        topic: '',
        transformerId: '',
        metadata: {},
        tags: [],
        customField: {},
      },
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      itemsPerPage: pageConfig.itemsPerPage,
      transformerOptions: [],
      brokerOptions: ['kafka-broker-1', 'kafka-broker-2', 'kafka-broker-3'],
      transValidate: null,
      nameValidate: null,
      brokerValidate: null,
      groupValidate: null,
      topicValidate: null,
      transId: '',
      options: ['tag1', 'tag2', 'tag3'],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('connections', ['success', 'message', 'loading', 'connections', 'connection']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';

      return {
        [className]: this.brokerValidate === false,
      };
    },
    selectTransformerErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';

      return {
        [className]: this.transValidate === false,
      };
    },
    created() {
      return dateFormate(this.formData.created);
    },
  },
  watch: {
    connection() {
      this.formData = cloneDeep(this.connection);
    },
    formData: {
      deep: true,
      handler: function (val) {
        if (val) {
          if (val.transformerId) this.transValidate = true;
          if (val.name) this.nameValidate = true;
          if (val.groupId) this.groupValidate = true;
          if (val.brokers) this.brokerValidate = true;
          if (val.topic) this.topicValidate = true;
        }
      },
    },
    transformers: {
      deep: true,
      handler: function (val) {
        for (var i in val) {
          this.transformerOptions.push(val[i].name);
        }
      },
    },
  },
  methods: {
    ...mapActions('connections', ['handleUpdateKafkaConnections', 'handleGetKafkaConnectionsById']),
    initialize() {
      this.handleGetKafkaConnectionsById(this.$route.params.id)
          .then(data => {
            routerBreadcrumbs(this.$router.currentRoute, {
              name: data[0].name,
            });
          });
      },
    addMeta(meta) {
      this.formData.metadata = meta;
    },
    addTags(newTag) {
      this.options.push(newTag);
      this.formData.tags.push(newTag);
    },
    onChangeBroker(value) {
      this.brokerValidate = true;
      this.formData.brokers = value;
    },
    dateFormate,
    editConnection() {
      this.transValidate = !!this.formData.transformerId;
      this.nameValidate = !!this.formData.name;
      this.brokerValidate = !!this.formData.brokers;
      this.groupValidate = !!this.formData.groupId;
      this.topicValidate = !!this.formData.topic;

      const body = [
        {
          id: this.formData.id,
          objectType: this.formData.objectType,
          name: this.formData.name,
          brokers: this.formData.brokers,
          groupId: this.formData.groupId,
          topic: this.formData.topic,
          transformerId: this.formData.transformerId,
          metadata: this.formData.metadata,
          tags: this.formData.tags,
          customFields: this.formData.customFields,
        },
      ];
      if (
        this.transValidate &&
        this.nameValidate &&
        this.groupValidate &&
        this.topicValidate &&
        this.topicValidate
      )
        this.handleUpdateKafkaConnections(body);
    },
  },
};
</script>

<style lang="scss">
.edit-connection {
  height: 100%;
  .edit-connection-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
    }
  }
}
</style>
